import React, { useState, useEffect } from "react";
import { MdOutlineHome } from "react-icons/md";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

const StyledBreadCrumb = styled.div`
  height: 40px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 7px 5px 5px 0px rgba(0, 0, 0, 0.3);
  margin: 30px 0px 0px 30px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  transition: width 0.3s ease;
`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;

  svg {
    margin-right: 5px;
  }
`;

const Crumb = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  a {
    text-decoration: none;
    color: black;
    text-transform: capitalize;
  }

  &:not(:last-child)::after {
    content: '>';
    margin-left: 10px;
  }
`;



const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbWidth, setBreadcrumbWidth] = useState('auto');
  const [crumbs, setCrumbs] = useState([]);

  for (let i = 0; i < crumbs.length; i++) {
    crumbs[i] = crumbs[i].replace('-', ' ');
  }

  useEffect(() => {
    let totalWidth = 0
    const crumbsArray = location.pathname.split('/').filter(crumb => crumb !== '');
    const numCharacters = crumbsArray.reduce((acc, crumb) => acc + crumb.length, 0);
    const avgCharWidth = 10;
    const padding = 100;

    for (let i = 0; i < crumbsArray.length; i++) {
      if ('home' === crumbsArray[i]){
        totalWidth = (6 * avgCharWidth) + padding;
      }else{
        totalWidth = ((numCharacters + 6) * avgCharWidth) + padding;
      }
    }

    setBreadcrumbWidth(`${totalWidth}px`);
  }, [location]);

  useEffect(() => {
    const crumbsArray = location.pathname.split('/').filter(crumb => crumb !== '> ');
    setCrumbs(crumbsArray);
  }, [location]);

  return (
   <StyledBreadCrumb style={{ width: breadcrumbWidth }}>
      <HomeLink to="/home">
        <MdOutlineHome size={30} />
        Início
      </HomeLink>
      {crumbs.map((crumb, index) => (
        crumb === 'home' ? null : (
          <Crumb key={index}>
            <Link to={`/${crumb}`}>{crumb}</Link>
          </Crumb>
        )
      ))}
    </StyledBreadCrumb>
  );
};

export default Breadcrumb;
