import React from "react";
import { useLocation } from "react-router-dom";

import Container from "Components/container/Container";
import AnexoField from "Components/informationsField/AnexoField";
import TextoField from "Components/informationsField/TextoField";
import StatusField from "Components/informationsField/StatusField";
import CustomButtonCancelar from "Components/button/CustomButtonCancelar";

const RecursosDetalhes = () => {
  const location = useLocation();
  const data = location.state?.data;
  let temResposta = false;

  if (data.resposta !== "" || data.anexo_resposta !== "") {
    temResposta = true;
  }

  return (
    <Container titulo="Dados do Recurso">
      <AnexoField />
      <TextoField label="Justificativa" value={data.justificativa} />
      {temResposta && (
        <>
          <AnexoField label="Anexo da Resposta" />
          <TextoField label="Resposta" value={data.resposta} />
        </>
      )}
      <StatusField status={data.status} upperCase={true} centered={true} />
      <div
        style={{
          display: "flex",
          gap: "30px",
          width: "30%",
          justifyContent: "center",
        }}
      >
        <CustomButtonCancelar/>
      </div>
    </Container>
  );
};

export default RecursosDetalhes;
