export const hex = {
    azulPrimario: "#79c1e0",
    azulSecundario: "#04A3FB",
    azulTerciario: "#007C91",
    verdePrimario: "#00A86E",
    roxoPrimario: "#743E97",
    laranjaPrimario: "#F48120",
    vermelhoPrimario: "#FB0404",
    vermelhoSecundario: "#FF6F61",
    amareloPrimario: "#FFC700",
    cinzaPrimario: "#F6F6F6",
    cinzaSecundario: "#A1AAAF",
    coral: "#00ACC1",
    preto: "#000000",
    brancoPrimario: "#EBEBEB",
    brancoSecundario: "#ededed",
};

export const colors = {
    background: hex.brancoPrimario,
    header: hex.azulSecundario,
    cardBackground: hex.azulPrimario,
    cardBotao: hex.azulTerciario,
    cardIcon: hex.azulTerciario,
};
