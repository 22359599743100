import React, { lazy, Suspense } from "react";
import Loading from "Components/loading/Loading";

const Tabela = lazy(() => delayForDemo(import("./Tabela")));

const TabelaLoader = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Tabela {...props} />
    </Suspense>
  );
};

function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  }).then(() => promise);
}

export default TabelaLoader;
