import React, { useState } from "react";
import { hex } from "Colors";
import "./tooltip.css";

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-target"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {visible && (
        <div
          className="tooltip-box"
          style={{ backgroundColor: hex.azulTerciario }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
