import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoQuestion } from "react-icons/go";

import { useUserContext } from "contexts/UserContext";
import CustomButton from "Components/button/Button";
import Container from "Components/container/Container";
import Tooltip from "Components/tooltip/Tooltip";
import ModalGenerico from "Components/modal/ModalGenerico";

import fakeDataCertificados from "fakeData/certificados.json";

const RecursoCadastro = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const iconSize = 20;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [formData, setFormData] = useState({
    certificado: "",
    anexo: "",
    observacao: "",
  });

  let certificados = fakeDataCertificados.filter(
    (certificado) => certificado.perfil === user.nome
  );
  certificados = certificados.filter((certificado) => certificado.status === 0);
  certificados = certificados.map((certificado, index) => ({
    index: index,
    value: certificado.nome,
  }));

  const retornaSeTemCampoPreenchido = () => {
    return Object.values(formData).some((value) => value !== "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    handleCloseModal();
    navigate(-1);
  };

  const onBeforeCancel = () => {
    if (retornaSeTemCampoPreenchido()) {
      handleOpenModal();
    } else {
      handleCancel();
    }
  };

  const handleSubmit = () => {
    console.log(formData);
  };

  return (
    <>
      <Container titulo="Cadastro de Recurso">
        <form>
          <div>
            <div>
              <div className="label-icon">
                <label htmlFor="tipo-qualificacao">
                  Selecione o certificado indeferido:
                </label>
                <Tooltip text="Informe o tipo de qualificação do certificado">
                  <GoQuestion size={iconSize} />
                </Tooltip>
              </div>
              <select
                name="tipo"
                value={formData.tipo}
                onChange={handleChange}
                required
              >
                <option value="">Selecione</option>
                {certificados.map((opcao) => (
                  <option key={opcao.value} value={opcao.value}>
                    {opcao.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="label-icon">
              <label htmlFor="anexo">Anexo do recurso:</label>
              <Tooltip text="Insira o anexo com o certificado para avaliação">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <input
              type="file"
              name="anexo"
              className="file-input"
              value={formData.anexo}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="observacao">Justificativa:</label>
              <Tooltip text="Insira o anexo com o certificado para avaliação">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <textarea
              name="observacao"
              rows="4"
              value={formData.textarea}
              onChange={handleChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              width: "60%",
              marginLeft: "20%",
            }}
          >
            <CustomButton onClick={handleSubmit}>Cadastrar</CustomButton>
            <CustomButton variant="cancel" onClick={onBeforeCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Container>
      <ModalGenerico
        isOpen={isModalOpen}
        text="Se você sair, perderá seu progresso. Tem certeza que deseja sair?"
        onCancel={handleCloseModal}
        onProceed={handleCancel}
      />
    </>
  );
};

export default RecursoCadastro;
