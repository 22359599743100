import React from 'react';
import { DivFooter } from 'Components/layout/layoutProject';

function Footer() {
  return (
    <footer>
      <DivFooter>
        <p>Desenvolvido pela Gerência de Sistemas - SECTP</p>
      </DivFooter>
    </footer>
  );
}

export default Footer;
