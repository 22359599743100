import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import styled from "styled-components";
import ModalGenerico from "Components/modal/ModalGenerico";
import ModalAvaliacao from "Components/modal/ModalAvaliar";
import Container from "Components/container/Container";
import CustomButton from "Components/button/Button";
import Tabela from "Components/table/Tabela";
import Field from "Components/informationsField/Field";
import TextoField from "Components/informationsField/TextoField";
import AnexoField from "Components/informationsField/AnexoField";
import StatusField from "Components/informationsField/StatusField";
import { Notify, ToastType } from 'Components/notify/notify';

const Informacoes = styled.div`
  font-size: 18px;
  margin-left: 100px;
  margin-right: 100px;
`;

const GridData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`;

const CertificadoDados = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenAvaliar, setModalOpenAvaliar] = useState(false);
  const location = useLocation();
  const { typeProfile, user } = useUserContext();
  const data = location.state?.data;
  const [ehVisivelTabela, setEhVisivelTabela] = useState(false);
  const [users, setUsers] = useState([]);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

  const visibilizarTabela = () => {
    setEhVisivelTabela(!ehVisivelTabela);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalAvaliar = () => {
    setModalOpenAvaliar(true);
  };

  const handleCloseModalAvaliar = () => {
    setModalOpenAvaliar(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("http://localhost:3001/users");
        if (!response.ok) {
          throw new Error("Erro ao buscar usuários");
        }
        const allUsers = await response.json();
        setUsers(allUsers.filter((user) => user.nome === data.perfil));
      } catch (error) {
        console.error("Erro ao buscar usuários", error);
      }
    };

    fetchUsers();
  }, [data.perfil]);

  const historico = [
    {
      fase: "Submissão",
      data: data.dataCadastro,
      pessoa: data.perfil,
      status: getStatusDescricao(data.status, 0),
    },
    {
      fase: "Análise do RH",
      data: data.dataConferencia,
      pessoa: data.quemConferiu,
      status: getStatusDescricao(data.status, 1),
    },
    {
      fase: "Análise do Gestor e Especialista",
      data: data.dataDeferimentoOuIndeferimento,
      pessoa: data.quemDeferiuOuIndeferiu,
      status: getStatusDescricao(data.status, 2),
    },
  ];

  const handleEditClick = () => {
    // console.log(`HANDLE ${users}`)
    if (!user || !user.vinculos || !data) {
      navigate(-1);
    } else {
      navigate("/certificados/editar", { state: { data, users } });
    }
  };

  function getStatusDescricao(status, linha) {
    switch (status) {
      case 0:
        return linha === 0 ? "Pendente" : "Aguardando";
      case 1:
        if (linha === 0) return "Concluído";
        if (linha === 1) return "Conferido";
        return "Aguardando";
      case 2:
        return linha === 2 ? "Deferido" : getStatusDescricao(1, linha);
      case 3:
        return linha === 2 ? "Indeferido" : getStatusDescricao(1, linha);
      case 4:
        return linha === 2 ? "Recurso" : getStatusDescricao(1, linha);
      default:
        return "";
    }
  }

  function getVinculo(user, vinculoId) {
    if (!user || !user.vinculos) {
      return '';
    }
    const vinculo = user.vinculos.find(v => v.id === vinculoId);
    return vinculo ? `${vinculo.NUMVINC} - ${vinculo.CARGO_NOME}` : '';
  }

  const headerHistorico = ["Fase", "Data", "Responsável", "Status"];

  const backPage = () => {
    navigate(-1);
  };

  const conferir = async () => {
    data.status = 1;
    data.dataConferencia = formattedDate;
    data.quemConferiu = user.nome;
    try {
      const response = await fetch(`http://localhost:3001/certificates/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        Notify({ message: `Certificado conferido com sucesso!`, type: ToastType.Success });
        handleCloseModal();
        navigate("/conferidos");
      } else {
        const errorData = await response.json();
        console.error("Erro ao conferir certificado:", errorData);
        Notify({ message: `Erro ao conferir certificado!`, type: ToastType.Error });
        handleCloseModal();
        navigate("/pendentes");
      }
    } catch (error) {
      console.error("Erro ao conferir certificado:", error);
      Notify({ message: `Erro ao conferir certificado!`, type: ToastType.Error });
      handleCloseModal();
      navigate("/pendentes");
    }
  };
  const avaliar = async (resultado, motivo) => {
    data.status = resultado === 'Deferido' ? 2 : 3;
    data.dataDeferimentoOuIndeferimento = formattedDate;
    data.quemDeferiuOuIndeferiu = user.nome;
    data.resposta = motivo;

    try {
      const response = await fetch(`http://localhost:3001/certificates/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        Notify({ message: 'Certificado ' + resultado +  ' com sucesso!', type: ToastType.Success });
        handleCloseModalAvaliar();
        if( resultado === 'Deferido'){
          navigate("/deferidos");
        }else{
          navigate("/indeferidos");
        }
      } else {
        const errorData = await response.json();
        Notify({ message: 'Erro ao ' + errorData +  ' certificado:', type: ToastType.Error });
        handleCloseModalAvaliar();
        navigate("/certificados");
      }
    } catch (error) {
      Notify({ message: 'Erro ao ' + resultado +  ' certificado:', type: ToastType.Error });
      handleCloseModalAvaliar();
      navigate("/certificados");
    }
  };
  return (
    <Container titulo={"Dados do Certificado"}>
      <Informacoes>
        <Field label="Proprietário do certificado" value={data.perfil} />
        <Field label="Vínculo" value={getVinculo(users[0], data.vinculo)} />
        <Field label="Nome do curso" value={data.nome} />
        <Field label="Instituição" value={data.instituicao} />
        <Field label="Tipo de qualificação" value={data.tipoQualificacao} />
        <Field label="Carga Horária" value={data.cargaHoraria} />
        <GridData>
          <Field label="Data de início" value={data.dataInicio} />
          <Field label="Data de término" value={data.dataFim} />
          <Field label="Data de cadastro" value={data.dataCadastro} />
          <Field label="Data de conferência" value={data.dataConferencia} />
        </GridData>
        <AnexoField />
        <TextoField label="Observação" value={data.observacao} />
        <StatusField
          status={data.status}
          upperCase={true}
          centered={true}
          onClickAction={visibilizarTabela}
        />
        {ehVisivelTabela && (
          <Tabela data={historico} header={headerHistorico} />
        )}
      </Informacoes>
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "space-between",
          width: "60%",
          marginLeft: "20%",
        }}
      >
        {data.status === 0 && <CustomButton onClick={handleEditClick}>Editar</CustomButton>}
        {typeProfile === 1 && data.status === 0 && <CustomButton onClick={handleOpenModal}>Conferir</CustomButton>}
        {typeProfile === 2 && data.status === 1 && <CustomButton onClick={handleOpenModalAvaliar}>Avaliar</CustomButton>}
        {typeProfile === 3 && <CustomButton onClick={handleOpenModalAvaliar}>Avaliar</CustomButton>}
        <CustomButton variant="cancel" onClick={backPage}>
          Sair
        </CustomButton>
      </div>
      <ModalGenerico
        isOpen={isModalOpen}
        text='Deseja confirmar as informações contidas neste certificado? Após a confirmação, não será mais possível editá-las. Se todas as informações estiverem corretas, clique em "Confirmar". Caso contrário, clique em "Cancelar".'
        onCancel={handleCloseModal}
        textoOk="Confirmar"
        textoCancelar="Cancelar"
        onProceed={conferir}
      />
       {/* Modal para avaliar o certificado */}
       <ModalAvaliacao
        isOpen={isModalOpenAvaliar}
        onCancel={handleCloseModalAvaliar}
        onConfirm={avaliar}
      />
    </Container>
  );
};

export default CertificadoDados;
