import React, { useState } from 'react';
import {hex} from '../../Colors';
import styled from 'styled-components';

const StyledButton = styled.button`
  border-radius: 8px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  border-color: transparent;
  width: 100%;
  height: 50px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: background 0.3s ease;
`;

const buttonVariantStyles = {
  default: {
    backgroundColor: hex.cinzaSecundario,
    color: hex.cinzaPrimario,
    hover: {
      backgroundColor: hex.cinzaSecundario,
    },
    active: {
      backgroundColor: hex.cinzaSecundario,
      animation: 'blink 0.3s ease',
    },
  },
  info: {
    backgroundColor: hex.azulSecundario,
    color: hex.cinzaPrimario,
    hover: {
      backgroundColor: hex.azulSecundario,
    },
    active: {
      backgroundColor: hex.azulSecundario,
      animation: 'blink 0.3s ease',
    },
  },
  save: {
    backgroundColor: hex.coral,
    color: hex.cinzaPrimario,
    hover: {
      backgroundColor: hex.coral,
    },
    active: {
      backgroundColor: hex.coral,
      animation: 'blink 0.3s ease',
    },
  },
  cancel: {
    color: hex.cinzaPrimario,
    backgroundColor: hex.vermelhoPrimario,
    hover: {
      borderColor: hex.vermelhoPrimario,
    },
    active: {
      backgroundColor: hex.vermelhoPrimario,
      animation: 'blink 0.3s ease',
    },
  },
};

const CustomButton = ({
  variant = 'save',
  children,
  type = 'button',
  style = {},
  ...props
}) => {

  const [isHover, setIsHover] = useState(false)

  const baseStyle = buttonVariantStyles[variant] || {};
  const hoverStyle = isHover && baseStyle.hover ? baseStyle.hover : {}

  const combinedStyle = { ...baseStyle, ...hoverStyle, ...style };


  return (
    <StyledButton
      variant={variant}
      type={type}
      style={combinedStyle}
      {...props}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={props.onClick}
    >
      {children}
    </StyledButton>
  )
};

export default CustomButton;
