import React, { useState } from "react";
import { Notify, ToastType } from "Components/notify/notify";
import { useNavigate } from "react-router-dom";

import { useUserContext } from "contexts/UserContext";
import CertificadoForm from "./CertificadoForm";

const CertificadoCadastrar = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const [formData, setFormData] = useState({
    id: Math.random().toString(36).substr(2, 9),
    nome: "",
    instituicao: "",
    tipoQualificacao: "",
    cargaHoraria: "",
    dataInicio: "",
    dataFim: "",
    anexo: "",
    observacao: "",
    status: 0,
    dataCadastro: formattedDate,
    dataConferencia: null,
    quemConferiu: null,
    dataDeferimentoOuIndeferimento: null,
    quemDeferiuOuIndeferiu: null,
    resposta: null,
    ativo: false,
    regional: user.regional,
    vinculo: "",
    perfil: user.nome,
  });

  const handleSubmit = async () => {
    const novoCertificado = {
      ...formData,
      vinculo: parseInt(formData.vinculo, 10),
    };
    try {
      const response = await fetch("http://localhost:3001/certificates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(novoCertificado),
      });
      if (response.ok) {
        Notify({
          message: `Certificado Cadastrado com sucesso!`,
          type: ToastType.Success,
        });
        navigate(-1);
      } else {
        Notify({
          message: `Erro ao cadastrar certificado!`,
          type: ToastType.Error,
        });
      }
    } catch (error) {
      Notify({
        message: `Erro ao cadastrar certificado!`,
        type: ToastType.Error,
      });
    }
  };

  return (
    <CertificadoForm
      formData={formData}
      setFormData={setFormData}
      user={user}
      handleSubmit={handleSubmit}
      titulo="Cadastrar Certificado"
    />
  );
};

export default CertificadoCadastrar;
