import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoCertifica from "Assets/logo_qualifica.png";
import CustomButton from "Components/button/Button";
import { Notify, ToastType } from 'Components/notify/notify';
import { useUserContext } from "contexts/UserContext";
import "Pages/styles/perfil.css";

const Perfil = () => {
  const navigate = useNavigate();
  const { user, setTypeProfile } = useUserContext();
  const [valorSelecionado, setValorSelecionado] = useState(0);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setValorSelecionado(value);
  };

  const handleSubmit = () => {
    setTypeProfile(valorSelecionado);
    if (user.tipo.includes(parseInt(valorSelecionado, 10))) {
      navigate("/home");
    }else{
      Notify({ message: `Você não possui este tipo de perfil!`, type: ToastType.Error });
    }
  };

  return (
    <div className="selectPerfil-container">
      <div className="selectPerfil-form">
        <img src={logoCertifica} alt="Imagem de seduc" />
        <form onSubmit={handleSubmit}>
          <label htmlFor="cars">Selecione seu perfil:</label>
          <select id="cargo" name="cargo" value={valorSelecionado} onChange={handleSelectChange}>
            <option value="0">Servidor</option>
            <option value="1">RH - Regional</option>
            <option value="2">Especialista</option>
            <option value="3">Gestor de Quadro</option>
            <option value="4">Administrador</option>
          </select>
          <CustomButton onClick={handleSubmit}>
            Entrar
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default Perfil;
