import React, { useEffect, useState } from "react";
import CardGroup from "Components/card/CardGroup";
import { GoVerified } from "react-icons/go";
import { MdOutlinePendingActions, MdOutlineBlock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineException } from "react-icons/ai";
import { useUserContext } from "contexts/UserContext";
import { BsClipboard2Check } from "react-icons/bs";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { GrDocumentDownload } from "react-icons/gr";
import {MdOutlineDataset} from "react-icons/md";


const HomeRh = () => {

  const navigate = useNavigate();
  const { user } = useUserContext();
  let itens = [];
  const [certificados, setCertificados] = useState([]);

  const handleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const fetchCertificados = async () => {
      try {
        const response = await fetch("http://localhost:3001/certificates");
        let allCertificados = await response.json();
        setCertificados(allCertificados.filter((certificado) => certificado.regional === user.regional));
      } catch (error) {
        console.error("Erro ao buscar certificados", error);
      }
    };
    fetchCertificados();
  }, [user]);

  const pendente = certificados.filter((certificado) => certificado.status === 0);
  const verificado = certificados.filter((certificado) => certificado.status === 1);
  const deferido = certificados.filter((certificado) => certificado.status === 2);
  const indeferido = certificados.filter((certificado) => certificado.status === 3);
  const recurso = certificados.filter((certificado) => certificado.status === 4);

  itens = [
    {
      id: 0,
      title: "Pendentes",
      variant: "yellow",
      type: "titleIconValue",
      value: pendente.length,
      icon: MdOutlinePendingActions,
      buttonAction: () => {
        handleClick("/pendentes");
      },
    },
    {
      id: 1,
      title: "Conferidos",
      variant: "orange",
      type: "titleIconValue",
      value: verificado.length,
      icon: BsClipboard2Check,
      buttonAction: () => {
        handleClick("/conferidos");
      },
    },
    {
      id: 2,
      title: "Deferidos",
      variant: "green",
      type: "titleIconValue",
      value: deferido.length,
      icon: GoVerified,
      buttonAction: () => {
        handleClick("/deferidos");
      },
    },
    {
      id: 3,
      title: "Indeferidos",
      variant: "red",
      type: "titleIconValue",
      value: indeferido.length,
      icon: MdOutlineBlock,
      buttonAction: () => {
        handleClick("/indeferidos");
      },
    },
    {
      id: 4,
      title: "Recursos",
      variant: "purple",
      type: "titleIconValue",
      value: recurso.length,
      icon: AiOutlineException,
      buttonAction: () => {
        handleClick("/recursos");
      },
    },
    {
      id: 5,
      title: "Dashboard",
      variant: "blue",
      type: "titleIcon",
      icon: TbLayoutDashboardFilled,
      buttonAction: () => {
        handleClick("/certificados");
      },
    },
    {
      id: 6,
      title: "Relatórios",
      variant: "blue",
      type: "titleIcon",
      icon: GrDocumentDownload,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    },
    {
      id: 7,
      title: "Normativa",
      variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDataset,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    }
  ];
  return (
    <>
      <CardGroup menuItems={itens} />
    </>
  );
};

export default HomeRh;
