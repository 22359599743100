import React, { useEffect, useState } from "react";
import { Notify, ToastType } from "Components/notify/notify";
import { useLocation, useNavigate } from "react-router-dom";

import CertificadoForm from "../CadastrarCertificado/CertificadoForm";

const EditarCertificado = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const data = location.state?.data;
  const userSelecionado = location.state?.users;
  const path = "/pendentes";

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        nome: data.nome,
        instituicao: data.instituicao,
        tipoQualificacao: data.tipoQualificacao,
        cargaHoraria: data.cargaHoraria,
        dataInicio: data.dataInicio,
        dataFim: data.dataFim,
        anexo: data.anexo,
        observacao: data.observacao,
        status: data.status,
        dataCadastro: data.dataCadastro,
        dataConferencia: data.dataConferencia,
        quemConferiu: data.quemConferiu,
        dataDeferimentoOuIndeferimento: data.dataDeferimentoOuIndeferimento,
        quemDeferiuOuIndeferiu: data.quemDeferiuOuIndeferiu,
        resposta: data.resposta,
        ativo: data.ativo,
        regional: data.regional,
        vinculo: data.vinculo,
        perfil: data.perfil,
      });
    }
  }, [data]);

  // TODO
  // Esse useEffect está silenciando um problema de ler
  // undefined, verificar mais a fundo quando estiver
  // conectado com o back se esse problema sumiu
  useEffect(() => {
    if (userSelecionado === undefined) {
      navigate(path);
    }
  }, [userSelecionado, navigate, data]);

  if (userSelecionado === undefined) {
    return null;
  }

  const handleSubmit = async () => {
    const certificadoAtualizado = {
      ...formData,
      vinculo: parseInt(formData.vinculo),
    }; // Certifique-se de que vinculo é um número
    try {
      const response = await fetch(
        `http://localhost:3001/certificates/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(certificadoAtualizado),
        }
      );
      if (response.ok) {
        Notify({
          message: `Certificado editado com sucesso!`,
          type: ToastType.Success,
        });
        navigate(path);
      } else {
        Notify({
          message: `Erro ao editar certificado!`,
          type: ToastType.Error,
        });
        navigate(path);
      }
    } catch (error) {
      console.log("ERROU ", error);
      Notify({
        message: `Erro ao editar certificado!`,
        type: ToastType.Error,
      });
      navigate(path);
    }
  };

  console.log(userSelecionado);

  return (
    <CertificadoForm
      formData={formData}
      setFormData={setFormData}
      user={userSelecionado[0]}
      handleSubmit={handleSubmit}
      titulo="Editar Certificado"
      isEditar={false}
    />
  );
};

export default EditarCertificado;
