import React from "react";
import { Outlet } from "react-router-dom";
import './layoutSidebar.css'

//components
import Sidebar from "../menu/sidebar/Sidebar";
import ProfileBar from "../menu/profilebar/Profilebar";
import Breadcrumb from "../menu/breadcrumb/Breadcrumb";

function LayoutSidebar() {
  return (
    <>
      <div
        className="grid-container"
      >
        <Sidebar />
        <div style={{ display: "grid", gridTemplateRows: "60px 1fr", rowGap: "30px", paddingRight: "100px"}}>
          <Breadcrumb />
          <Outlet />
        </div>
      </div>
      <ProfileBar />
    </>
  );
}

export default LayoutSidebar;
