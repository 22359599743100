import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "contexts/UserContext";

const SidebarItem = ({ icon, texto, path = "/home", children }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const { setTypeProfile } = useUserContext();

  const handleClick = () => {
    if (texto === "Sair") {
      setTypeProfile(null);
      localStorage.removeItem('tipoPerfil');
    }

    if (children && texto === "Certificados") {
      setIsExpanded(!isExpanded);
    } else if (path) {
      navigate(path);
    }
  };

  return (
    <div>
      <div onClick={handleClick}>
        <li style={{marginBottom: children ? '0' : '10px'}} className="text-icon">
          <span>{icon}</span>
          &nbsp;&nbsp;&nbsp;<b>{texto}</b>
        </li>
      </div>
      {isExpanded && children &&  (
        children.map((child, index) => (
          <div className="sidebar-submenu" key={index} onClick={() => navigate(child.path)}>
            <li className="text-icon">
              <span>{child.icon}</span>
              &nbsp;&nbsp;&nbsp;<b>{child.texto}</b>
            </li>
          </div>
        ))
      )}
    </div>
  );
};

export default SidebarItem;

