import "./App.css";
import React from "react";
import {
  createBrowserRouter,
  Route,
  Navigate,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

// src
import Login from "./Login";
import Perfil from "Pages/Perfil/Perfil.js";
import "react-toastify/dist/ReactToastify.css";

// components
import LayoutSidebar from "Components/layout/LayoutSidebar";
import LayoutContext from "Components/layout/LayoutContext";

// pages
import Home from "Pages/Home/Home";
import Sobre from "Pages/Sobre/Sobre";
import Suporte from "Pages/Suporte/Suporte";
import CertificadoDados from "Pages/Certificados/CertificadoDados/CertificadoDados";
import ListaCertificados from "Pages/Certificados/ListaCertificados/ListaCertificados";
import ListaCertificadosUsuario from "Pages/Certificados/ListaCertificadosUsuario/ListaCertificadosUsuario";
import Recursos from "Pages/Recursos/Recursos";
import Normativas from "Pages/Normativas/Normativas";
import RecursosDetalhes from "Pages/Recursos/RecursosDetalhes";
import RecursoCadastro from "Pages/Recursos/RecursoCadastro";
import Relatorios from "Pages/Relatorios/Relatorios";
import CertificadoCadastrar from "Pages/Certificados/CadastrarCertificado/CertificadoCadastrar";
import EditarCertificado from "Pages/Certificados/EditarCertificado/EditarCertificado";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/login" replace />} />

      <Route element={<LayoutContext />}>
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/login" element={<Login />} />

        <Route element={<LayoutSidebar />}>
          <Route path="/home" element={<Home />} />

          <Route path="/certificados">
            <Route index element={<ListaCertificadosUsuario />} />
            <Route path="detalhes" element={<CertificadoDados />} />
            <Route path="cadastrar" element={<CertificadoCadastrar />} />
            <Route path="editar" element={<EditarCertificado />} />
          </Route>

          <Route path="/deferidos">
            <Route index element={<ListaCertificados status={2} />} />
            <Route path="detalhes" element={<CertificadoDados />} />
          </Route>

          <Route path="/indeferidos">
            <Route index element={<ListaCertificados status={3} />} />
            <Route path="detalhes" element={<CertificadoDados />} />
          </Route>

          <Route path="/pendentes">
            <Route index element={<ListaCertificados status={0} />} />
            <Route path="detalhes" element={<CertificadoDados />} />
            <Route path="editar" element={<EditarCertificado />} />
          </Route>

          <Route path="/conferidos">
            <Route index element={<ListaCertificados status={1} />} />
            <Route path="detalhes" element={<CertificadoDados />} />
          </Route>

          <Route path="/recursos">
            <Route index element={<Recursos />} />
            <Route path="detalhes" element={<RecursosDetalhes />} />
            <Route path="cadastrar" element={<RecursoCadastro />} />
          </Route>

          <Route
            path="/tabela-usuario"
            element={<ListaCertificadosUsuario />}
          />
          <Route path="/normativas" element={<Normativas />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/suporte" element={<Suporte />} />
          <Route path="/relatorios" element={<Relatorios />} />
        </Route>
      </Route>
    </>
  )
);

const App = () => {
  const pageTitle = "Certifica - SEDUC TOCANTINS";

  React.useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <div>
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
