import React, { useEffect, useState } from "react";
import { PiCertificate } from "react-icons/pi";
import { AiOutlineException } from "react-icons/ai";
import { LuFilePlus2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import CardGroup from "Components/card/CardGroup";
import {MdOutlineDataset} from "react-icons/md";

const HomeServidor = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [certificadosCount, setCertificadosCount] = useState(0);
  const [recursosCount,] = useState(0);

  useEffect(() => {
    const fetchCertificadosCount = async () => {
      try {
        const response = await fetch(`http://localhost:3001/certificates/count/${user.nome}`);
        if (response.ok) {
          const data = await response.json();
          setCertificadosCount(data.count);
        } else {
          console.error("Erro ao buscar contagem de certificados");
        }
      } catch (error) {
        console.error("Erro ao buscar contagem de certificados", error);
      }
    };

    fetchCertificadosCount();
  }, [user.nome]);

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Cadastrar",
      variant: "green",
      type: "titleIcon",
      icon: LuFilePlus2,
      buttonAction: () => {
        handleClick("/certificados/cadastrar");
      },
    },
    {
      id: 1,
      title: "Certificados",
      variant: "blue",
      type: "titleIconValue",
      value: certificadosCount,
      icon: PiCertificate,
      buttonAction: () => {
        handleClick("/certificados");
      },
    },
    {
      id: 2,
      title: "Recursos",
      variant: "purple",
      type: "titleIconValue",
      value: recursosCount,
      icon: AiOutlineException,
      buttonAction: () => {
        handleClick("/recursos");
      },
    },
    {
      id: 3,
      title: "Normativa",
      variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDataset,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    }
  ];

  return <CardGroup menuItems={itens} />;
};

export default HomeServidor;

