import React from 'react'
import styled from 'styled-components';

const FieldContainer = styled.div`
  margin-bottom: 15px;
`;

const FieldLabel = styled.b`
  margin-right: 5px;
`;

const FieldValue = styled.span``;

const Field = ({ label, value }) => (
  <FieldContainer>
    <FieldLabel>{label}:</FieldLabel>
    <FieldValue>{value}</FieldValue>
  </FieldContainer>
);

export default Field;
