import React, { createContext, useContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? storedToken : null;
  });

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('perfil');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [typeProfile, setTypeProfile] = useState(() => {
    const storedTypes = localStorage.getItem('tipoPerfil');
    return storedTypes ? Number(storedTypes) : null;
  });

  const setProfileNumber = (value) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      setTypeProfile(numericValue);
      localStorage.setItem('tipoPerfil', numericValue);
    }
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem('token', token);
      localStorage.setItem('tipoPerfil', String(typeProfile));
      localStorage.setItem('perfil', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('perfil');
      localStorage.removeItem('tipoPerfil');
    }
  }, [user, token, typeProfile]);


  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, typeProfile, setTypeProfile: setProfileNumber  }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
