import React from 'react'
import styled from "styled-components";

const AnexoContainer = styled.div`
  font-size: 18px;
  padding-bottom: 15px;
`;

const CampoAnexo = styled.div`
  display: flex;
  font-size: 22px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed black;
  height: 60px;
`;

const AnexoField = ({ label="Anexo", value="Visualizar Documento" }) => (
  <AnexoContainer>
    <b>{label}:</b>
    <CampoAnexo>{value}</CampoAnexo>
  </AnexoContainer>
)

export default AnexoField
