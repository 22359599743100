import React from "react";
import { Outlet } from "react-router-dom";
import { UserProvider } from "contexts/UserContext";
import { CertificadosProvider } from "contexts/CertificadosContext";

function LayoutContext() {
  return (
    <>
      <UserProvider>
        <CertificadosProvider>
          <Outlet />
        </CertificadosProvider>
      </UserProvider>
    </>
  );
}

export default LayoutContext;
