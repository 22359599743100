import React from "react";
import styled from "styled-components";

const TextoFieldContainer = styled.div`
  font-size: 18px;
  padding-bottom: 15px;
`;

const CampoObservacao = styled.div`
  background-color: #ebebeb;
  font-size: 20px;
  padding: 20px;
  font-weight: 500;
`;

const TextoField = ({ label, value }) => (
  <TextoFieldContainer>
    <b>{label}:</b>
    <CampoObservacao>{value}</CampoObservacao>
  </TextoFieldContainer>
);

export default TextoField;
