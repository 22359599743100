import React, { useState, useEffect} from "react";
import {
  MdOutlineHome,
  MdOutlineDataset,
  MdOutlineInfo,
  MdOutlineVerified,
  MdOutlineBlock,
  MdOutlinePendingActions,
  MdLogout,
  MdSupportAgent
} from "react-icons/md";
import { GrDocumentDownload } from "react-icons/gr";
import { BsClipboard2Check } from "react-icons/bs";
import logoSVG from '../../../Assets/logo_certifica.svg';

import SidebarItem from "Components/menu/sidebarItem/sidebarItem";

import { useUserContext } from "contexts/UserContext";
import "./sidebar.css";

const Sidebar = () => {
  const { typeProfile } = useUserContext();
  const [expanded, setExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth > 780) {
        setExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const handleClick = () => {
    if (windowWidth <= 780) {
      setExpanded(!expanded);
    }else{
      setExpanded(false);
    }
  };

  let sidebarItens = [];

  if (typeProfile === 0) {
    sidebarItens = [
      {
        icon: <MdOutlineVerified size={30} />,
        texto: "Certificados",
        path: "/certificados",
      },

    ];
  } else if (typeProfile !== 0) {
    sidebarItens = [
      {
        icon: <MdOutlineVerified size={30} />,
        texto: "Certificados",
        children: [
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Conferidos",
            path: "/conferidos",
          },
          {
            icon: <MdOutlineVerified size={30} />,
            texto: "Deferidos",
            path: "/deferidos",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Indeferidos",
            path: "/indeferidos",
          },
          {
            icon: <MdOutlinePendingActions size={30} />,
            texto: "Pendentes",
            path: "/pendentes",
          },
        ],
      },
      {
        icon: <GrDocumentDownload  size={30} />,
        texto: "Relatórios",
        path:"/relatorios",
      },
    ]
  }

  return (
    <div className={expanded ?  'expanded' : 'sidebar'}>
      <div className={expanded ? 'expanded-main' : 'sidebar-main'}>
        <div className="titulo" onClick={handleClick}>
          <img className="titulo-logo" src={logoSVG} alt="Logo Certifica"/>
          <h2 className="titulo-nome">Certifica</h2>
        </div>
        <div style={{ display: 'grid', gridTemplateRows: '70% 20%', height: '100%' }}>
          <ul style={{ height: '100%', width: '100%' }}>
            <SidebarItem
              icon={<MdOutlineHome size={30} />}
              texto={"Página Inicial"}
              typeProfile={typeProfile}
            />
            {sidebarItens.map((item, index) => (
              <SidebarItem
                key={index}
                icon={item.icon}
                texto={item.texto}
                path={item.path}
                children={item.children}
                typeProfile={typeProfile}
              />
            ))}
            <SidebarItem
              icon={<MdOutlinePendingActions size={30} />}
              texto="Recursos"
              path="/recursos"
            />
            <SidebarItem
              icon={<MdOutlineDataset size={30} />}
              texto={"Normativas"}
              path="/normativas"
            />
            <SidebarItem
              icon={<MdOutlineInfo size={30} />}
              texto={"Sobre"}
              path="/sobre"
            />
          </ul>
          <ul style={{ height: '100%', width: '100%' }}>
            <hr />
            <SidebarItem icon={<MdSupportAgent size={30} />} texto={"Suporte"} path="/suporte"/>
            <SidebarItem icon={<MdLogout size={30} />} texto={"Sair"} path="/perfil" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
