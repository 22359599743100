import Container from 'Components/container/Container'
import React from 'react'

const Sobre = () => {
  return (
    <Container titulo="Sobre">
      <p>O Certifica é um sistema da área de Recursos Humanos da Secretaria de Educação do Tocantins, desenvolvido pela Gerência de Sistemas da Diretoria de Tecnologia da Informação no ano de 2024 com o intuito de <strong>digitalizar e organizar os processos de entrega e validação dos certificados de especializações, mestrado, doutorado e cursos profissionalizantes</strong>.</p>
      <br/>
      <p>O sistema conta com 4 perfis, usuário, RH, especialista e gestor, sendo o principal deles o usuário. Este engloba todos os servidores da SEDUC e é utilizado para o envio de documentação comprovatória para registro de informações, além de utilizado como comprovante para possíveis progressões de carreira com certificados de mestrado e de doutorado.</p>
      <br/>
      <p>Como usuário ativo do programa Certifica é de suma importância que o cadastro de qualquer certificado seja precedido por uma revisão de todos os campos preenchidos pelo próprio usuário antes de salvar no sistema para facilitar o fluxo e reduzir o tempo de espera dos processos.</p>
      <br/>
      <p>Ressaltamos que com o objetivo de padronizar e criar imagens ou layouts para os sistemas em desenvolvimento desta Secretaria, a Gerência de Sistemas em parceria à Diretoria de Comunicação, realiza a criação e validação da parte gráfica, visando garantir a coerência visual com a identidade da Seduc – TO.</p>
      <br/>
      <p>Por último, a equipe da gerência de sistemas agradece a preferência e deseja uma boa experiência a todos os usuários.</p>
    </Container>
  )
}

export default Sobre
