import React, { useState, useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import styled from "styled-components";


import { useUserContext } from "contexts/UserContext";

const Container = styled.div`
  display: flex;
  position: fixed;
  margin: 25px 50px 0px 0px;
  top: 0;
  right: 0;
`;

const StyledIcon = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  margin: 25px 50px 0px 0px;
  border-radius: 30px;
  box-shadow: 7px 5px 5px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, box-shadow 0.5s;
  z-index: 3;

  &:hover {
    color: #ededed;
    background-color: #00acc1;
    box-shadow: 8px 7px 7px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
`;

const StyledGambiarra = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  background-color: ${(props) =>
    props.issliding === "true" ? "#ebebeb" : "transparent"};
  width: 80px;
  height: 70px;
  border-radius: 0px 0px 0px 30px;
  z-index: 2;
`;

const StyledProfileBar = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isvisible === "true" ? "0" : "-200%")};
  height: 60px;
  background-color: #ffffff;
  margin: 25px 80px 0px 0px;
  border-top-left-radius: 30px 30px;
  border-bottom-left-radius: 30px 30px;
  ${
    "" /* box-shadow: ${(props) => (props.isVisible) ? "7px 5px 5px 0px rgba(0, 0, 0, 0.3)" : "none" }; */
  }
  box-shadow: ${(props) =>
    props.isSliding === "true" ? "none" : "7px 5px 5px 0px rgba(0, 0, 0, 0.3)"};
  display: flex;
  flex-direction: column;
  width: ${(props) => props.style.width};
  justify-content: center;
  align-items: flex-start;
  transition: right 1s, background-color 0.5s, box-shadow 0.5s;
  z-index: 1;

  &:hover {
    color: #ededed;
    background-color: #00acc1;
    box-shadow: 12px 10px 10px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 60px;
    background: #ededed;
    right: -5px;
    border-top-left-radius: 30px 30px;
    border-bottom-left-radius: 30px 30px;
  }

  span {
    margin-left: 30px;
  }
`;

const ProfileBar = () => {
  const { user, typeProfile } = useUserContext();
  const [namebWidth, setNameWidth] = useState("auto");
  const [isProfileBarVisible, setProfileBarVisible] = useState(true);
  const [isSliding, setIsSliding] = useState(false);

  const slide = () => {
    setIsSliding(true);

    setTimeout(() => {
      setIsSliding(false);
    }, 1000);
  };

  const typeMap = {
    0: "Servidor",
    1: "RH - Regional",
    2: "Especialista",
    3: "Gestor de Quadro",
    4: "Administrador",
  };

  const handleIconClick = () => {
    if (!isSliding) {
      slide();
      setProfileBarVisible(!isProfileBarVisible);
    }
  };

  useEffect(() => {
    let totalWidth = 0;
    const namesArray = user.nome.split(" ").filter((name) => name !== "");
    const numCharacters = namesArray.reduce(
      (acc, name) => acc + name.length,
      0
    );
    const avgCharWidth = 12;

    totalWidth = (numCharacters + 7) * avgCharWidth;

    setNameWidth(`${totalWidth}px`);
  }, [user.nome]);

  return (
    <Container>
      <StyledProfileBar
        isvisible={isProfileBarVisible.toString()}
        issliding={isSliding.toString()}
        style={{ width: namebWidth }}
      >
        <span style={{ fontSize: "16px" }}>{user.nome}</span>
        <span style={{ fontSize: "12px" }}>{typeMap[typeProfile] + " / " + user.regional}</span>
      </StyledProfileBar>
      <StyledIcon onClick={handleIconClick}>
        <BsPerson size={35} />
      </StyledIcon>
      <StyledGambiarra issliding={isSliding.toString()} />
    </Container>
  );
};

export default ProfileBar;
