import React from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';

const Modal = ({ isOpen, text, onCancel, onProceed, textoOk="Permanecer", textoCancelar="Sair" }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto'>{text}</p>
        <div
          style={{
            display: "flex",
            gap: "50px",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <CustomButton onClick={onProceed}>{textoOk}</CustomButton>
          <CustomButton variant="cancel" onClick={onCancel}>{textoCancelar}</CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Modal;
