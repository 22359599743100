import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useUserContext } from "contexts/UserContext";
import { MdOutlineSearch } from "react-icons/md";
import styled from "styled-components";
import StatusField from "Components/informationsField/StatusField";
import TabelaLoader from "Components/table/TabelaLoader";
import { useCertificadosContext } from "contexts/CertificadosContext";

const Link = styled.p`
  &:hover {
    color: #04a3fb;
    cursor: pointer;
  }
`;

const ListaCertificados = ({ status }) => {
  const navigate = useNavigate();
  const { user, typeProfile } = useUserContext();
  const { certificados, fetchCertificados, isLoaded } =
    useCertificadosContext();
  const [certificadoFiltrado, setCertificadoFiltrado] = useState([]);

  const clickDetalhes = useCallback(
    (id) => {
      const data = certificados.find((certificado) => certificado.id === id);
      navigate("detalhes", { state: { data } });
    },
    [certificados, navigate]
  );

  const handleClickUsuario = useCallback(
    (nome) => {
      const data = certificados.filter(
        (certificado) => certificado.perfil === nome
      );
      navigate("/tabela-usuario", { state: { data } });
    },
    [certificados, navigate]
  );

  const getVinculoNome = useCallback(async (nome, vinculoNumero) => {
    const perfis = await getPerfis();
    if (perfis !== false) {
      const perfil = perfis.find((perfil) => perfil.nome === nome);
      if (perfil) {
        try {
          const perfilEncontrado = perfil.vinculos[vinculoNumero - 1];
          return perfilEncontrado["CARGO_NOME"];
        } catch (error) {
          console.error("Error accessing vinculo:", error);
          return vinculoNumero;
        }
      } else {
        return vinculoNumero;
      }
    } else {
      return vinculoNumero;
    }
  }, []);

  useEffect(() => {
    const fetchAndProcessCertificados = async () => {
      if (!isLoaded) {
        await fetchCertificados();
      }

      let allCertificados = [...certificados];

      if (typeProfile === 0) {
        allCertificados = allCertificados.filter(
          (certificado) => certificado.perfil === user.nome
        );
      } else if (typeProfile === 1) {
        allCertificados = allCertificados.filter(
          (certificado) => certificado.regional === user.regional
        );
      }

      const filteredCertificados = allCertificados.filter(
        (certificado) => certificado.status === status
      );

      const processedCertificados = await Promise.all(
        filteredCertificados.map(async (certificado, index) => {
          const vinculoNumero = certificado.vinculo || 1;
          const vinculoNome = await getVinculoNome(
            certificado.perfil,
            vinculoNumero
          );
          return {
            id: index + 1,
            regional: certificado.regional,
            nome: (
              <Link onClick={() => handleClickUsuario(certificado.perfil)}>
                {certificado.perfil}
              </Link>
            ),
            certificado: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link onClick={() => clickDetalhes(certificado.id)}>
                  {certificado.nome}
                </Link>
                <StatusField status={certificado.status} />
              </div>
            ),
            vinculo: vinculoNumero + " - " + vinculoNome,
            acao: (
              <MdOutlineSearch
                size={25}
                onClick={() => clickDetalhes(certificado.id)}
              />
            ),
          };
        })
      );

      setCertificadoFiltrado(processedCertificados);
    };

    fetchAndProcessCertificados();
  }, [
    certificados,
    isLoaded,
    fetchCertificados,
    typeProfile,
    user,
    status,
    clickDetalhes,
    getVinculoNome,
    handleClickUsuario,
  ]);

  const getPerfis = async () => {
    try {
      const response = await fetch("http://localhost:3001/users");
      return await response.json();
    } catch (error) {
      console.error("Error fetching perfis:", error);
      return false;
    }
  };

  const certificadosHeader = [
    "ID",
    "Regional",
    "Nome",
    "Certificado",
    "Vínculo",
    "Ação",
  ];
  const fieldsToSearch = ["nome", "certificado", "regional", "vinculo"];

  return (
    <>
      <br />
      <TabelaLoader
        data={certificadoFiltrado}
        header={certificadosHeader}
        paginated={true}
        search={fieldsToSearch}
      />
    </>
  );
};

export default ListaCertificados;
