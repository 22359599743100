import React from "react";
import { useNavigate } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import styled from "styled-components";

import { hex } from "Colors";
import fakeDatasRecursos from "fakeData/recursos.json";
import Tabela from "Components/table/Tabela";
import StatusField from "Components/informationsField/StatusField";
import { useUserContext } from "contexts/UserContext";

const Link = styled.p`
  &:hover {
    color: #04a3fb;
    cursor: pointer;
  }
`;

const Recursos = () => {
  const navigate = useNavigate();
  const { typeProfile } = useUserContext();
  const isServidor = typeProfile === 0;

  const handleClickDetalhes = (id) => {
    const data = fakeDatasRecursos.find((recurso) => recurso.id === id);
    navigate("detalhes", { state: { data } });
  };

  const recursos = fakeDatasRecursos.map((recurso) => ({
    id: recurso.id,
    certificado: (
      <Link onClick={() => handleClickDetalhes(recurso.id)}>
        {recurso.certificado}
      </Link>
    ),
    perfil: recurso.perfil,
    status: <StatusField status={recurso.status} />,
  }));

  const recursoHeader = ["ID", "Certificado", "Perfil", "Status"];
  const searchTerm = ["certificado", "perfil"];

  return (
    <>
      <Tabela
        data={recursos}
        header={recursoHeader}
        paginated={true}
        search={searchTerm}
        hasAdd={isServidor}
      />
    </>
  );
};

export default Recursos;
