import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineSearch } from "react-icons/md";
import styled from "styled-components";

import { useUserContext } from "contexts/UserContext";
import { useCertificadosContext } from "contexts/CertificadosContext";
import StatusField from "Components/informationsField/StatusField";
import TabelaLoader from "Components/table/TabelaLoader";

const Link = styled.p`
  &:hover {
    color: #04a3fb;
    cursor: pointer;
  }
`;

const ListaCertificados = () => {
  const navigate = useNavigate();
  const { user, typeProfile } = useUserContext();
  const { certificados, fetchCertificados, isLoaded } =
    useCertificadosContext();
  const [certificadoFiltrado, setCertificadoFiltrado] = useState([]);
  const isServidor = typeProfile === 0;

  const clickDetalhes = useCallback(
    (id) => {
      const data = certificados.find((certificado) => certificado.id === id);
      navigate("detalhes", { state: { data } });
    },
    [certificados, navigate]
  );

  const handleClickUsuario = useCallback(
    (nome) => {
      const data = certificados.filter(
        (certificado) => certificado.perfil === nome
      );
      navigate("/tabela-usuario", { state: { data } });
    },
    [certificados, navigate]
  );

  const getVinculoNome = useCallback(async (nome, vinculoNumero) => {
    const perfis = await getPerfis();
    if (perfis !== false) {
      const perfil = perfis.find((perfil) => perfil.nome === nome);
      if (perfil) {
        try {
          const perfilEncontrado = perfil.vinculos[vinculoNumero - 1];
          return perfilEncontrado["CARGO_NOME"];
        } catch (error) {
          console.error("Error accessing vinculo:", error);
          return vinculoNumero;
        }
      } else {
        return vinculoNumero;
      }
    } else {
      return vinculoNumero;
    }
  }, []);

  const filterAndProcessCertificados = useCallback(async () => {
    let allCertificados = [...certificados];

    if (typeProfile === 0) {
      allCertificados = allCertificados.filter(
        (certificado) => certificado.perfil === user.nome
      );
    } else if (typeProfile === 1) {
      allCertificados = allCertificados.filter(
        (certificado) => certificado.regional === user.regional
      );
    }

    const filteredCertificados = allCertificados;

    const processedCertificados = await Promise.all(
      filteredCertificados.map(async (certificado, index) => {
        const vinculoNumero = certificado.vinculo || 1;
        const vinculoNome = await getVinculoNome(
          certificado.perfil,
          vinculoNumero
        );
        return {
          id: index + 1,
          regional: certificado.regional,
          nome: (
            <Link onClick={() => handleClickUsuario(certificado.perfil)}>
              {certificado.perfil}
            </Link>
          ),
          certificado: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link onClick={() => clickDetalhes(certificado.id)}>
                {certificado.nome}
              </Link>
              <StatusField status={certificado.status} />
            </div>
          ),
          vinculo: vinculoNumero + " - " + vinculoNome,
          acao: (
            <MdOutlineSearch
              size={25}
              onClick={() => clickDetalhes(certificado.id)}
            />
          ),
        };
      })
    );

    setCertificadoFiltrado(processedCertificados);
  }, [
    certificados,
    clickDetalhes,
    getVinculoNome,
    handleClickUsuario,
    typeProfile,
    user.nome,
    user.regional,
  ]);

  useEffect(() => {
    if (!isLoaded) {
      fetchCertificados();
    } else {
      filterAndProcessCertificados();
    }
  }, [
    isLoaded,
    certificados,
    typeProfile,
    user,
    fetchCertificados,
    filterAndProcessCertificados,
  ]);

  const getPerfis = async () => {
    try {
      const response = await fetch("http://localhost:3001/users");
      return await response.json(); // Assuming the response is JSON
    } catch (error) {
      console.error("Error fetching perfis:", error);
      return false;
    }
  };

  const certificadosHeader = [
    "ID",
    "Regional",
    "Nome",
    "Certificado",
    "Vínculo",
    "Ação",
  ];

  const fieldsToSearch = ["nome", "certificado", "regional", "vinculo"];

  return (
    <>
      <TabelaLoader
        data={certificadoFiltrado}
        header={certificadosHeader}
        paginated={true}
        search={fieldsToSearch}
        hasAdd={isServidor}
      />
    </>
  );
};

export default ListaCertificados;
