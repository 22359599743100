import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';

import motivoIndeferimento from "fakeData/motivosIndeferimento.json"

const ModalAvaliacao = ({ isOpen, onCancel, onConfirm }) => {
  const [deferido, setDeferido] = useState(false);
  const [motivo, setMotivo] = useState('');

  const handleDeferir = () => {
    setDeferido(true);
  };

  const handleIndeferir = () => {
    setDeferido(false);
  };

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value);
  };

  const handleConfirm = () => {
    if (deferido) {
      onConfirm('Deferido', motivo);
    } else {
      onConfirm('Indeferido', motivo);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto2'>
          Deseja confirmar a avaliação deste certificado? Após a confirmação, não será mais possível editar. Somente o especialista poderá modificar a avaliação.
        </p>
        <div className="radio-buttons">
          <label>
            <p className='opcao'>Deferir:</p>
            <input type="radio" name="avaliacao" value="Deferir" onChange={handleDeferir} />
          </label>
          <label>
            <p className='opcao'>Indeferir:</p>
            <input type="radio" name="avaliacao" value="Indeferir" onChange={handleIndeferir}/>
          </label>
        </div>
        <div className="motivo-select">
            <label className='opcao'>Motivo:</label>
            <select value={motivo} onChange={handleMotivoChange}>
              <option value="">Selecione o motivo</option>
              {motivoIndeferimento.map((motivo, index) => (
                <option key={index} value={motivo.descricao}>
                  {motivo.descricao}
                </option>
              ))}
            </select>
        </div>
        <CustomButton onClick={handleConfirm}>Confirmar</CustomButton>
        <CustomButton variant="cancel" onClick={onCancel}>Cancelar</CustomButton>
      </div>
    </div>
  );
};

export default ModalAvaliacao;
