import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Container from "Components/container/Container";
import Tooltip from "Components/tooltip/Tooltip";
import { GoQuestion } from "react-icons/go";
import CustomButton from "Components/button/Button";
import ModalGenerico from "Components/modal/ModalGenerico";
import { useUserContext } from "contexts/UserContext";
import "./cadastroCertificado.css";

// TODO user vai ser uma coisa no perfil servidor e outra no rh
const CertificadoForm = ({
  formData,
  setFormData,
  user,
  handleSubmit,
  titulo,
  isEditar=true,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [teveAlteracao, setTeveAlteracao] = useState(false);
  const iconSize = 23;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    handleCloseModal();
    navigate(-1);
  };

  const onBeforeCancel = () => {
    if (teveAlteracao) {
      handleOpenModal();
    } else {
      handleCancel();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setTeveAlteracao(true);
  };

  const handleVinculoChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      vinculo: e.target.value,
    }));
  };

  const handleSubmitEventDefault = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <>
      <Container titulo={titulo}>
        <form onSubmit={handleSubmitEventDefault}>
          <div>
            <div className="label-icon">
              <label htmlFor="vinculo">Vínculo:</label>
              <Tooltip text="Selecione o vínculo">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <select
              name="vinculo"
              value={formData.vinculo}
              onChange={handleVinculoChange}
              required
            >
              <option value="">Selecione</option>
              {user.vinculos ? (
                user.vinculos.map((vinculo) => (
                  <option key={vinculo.id} value={vinculo.NUMVINC}>
                    {`${vinculo.NUMVINC} - ${vinculo.CARGO_NOME}`}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Nenhum vínculo disponível
                </option>
              )}
            </select>
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="nome">Nome do curso:</label>
              <Tooltip text="Informe o nome do curso">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <select
              name="nome"
              value={formData.nomeCurso}
              onChange={handleVinculoChange}
              required
            >
              <option value="">Selecione</option>
              {user.nomes ? (
                user.nome.map((nome) => (
                  <option key={nome.id} value={nome.NUMVINC}>
                    {`${nome.NUMVINC} - ${nome.CARGO_NOME}`}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Nenhum curso disponível
                </option>
              )}
            </select>
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="instituicao">Instituição:</label>
              <Tooltip text="Informe a instituição de ensino">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <select
              name="instituicao"
              value={formData.instituicao}
              onChange={handleVinculoChange}
              required
            >
              <option value="">Selecione</option>
              {user.instituicaos ? (
                user.instituicao.map((instituicao) => (
                  <option key={instituicao.id} value={instituicao.NUMVINC}>
                    {`${instituicao.NUMVINC} - ${instituicao.CARGO_NOME}`}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Nenhuma instituição disponível
                </option>
              )}
            </select>
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="tipoQualificacao">Tipo de Qualificação:</label>
              <Tooltip text="Informe o tipo de qualificação do certificado">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <select
              name="tipoQualificacao"
              value={formData.tipoQualificacao}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              <option value="Pós-Graduação - Lato Sensu (Especialização)">
                Pós-Graduação - Lato Sensu (Especialização)
              </option>
              <option value="Pós-Graduação - Lato Sensu">
                Pós-Graduação - Lato Sensu
              </option>
              <option value="Pós-Graduação - Stricto Sensu - Mestrado">
                Pós-Graduação - Stricto Sensu - Mestrado
              </option>
              <option value="Pós-Graduação - Stricto Sensu - Doutorado">
                Pós-Graduação - Stricto Sensu - Doutorado
              </option>
            </select>
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="cargaHoraria">Carga Horária:</label>
              <Tooltip text="Informe a carga horária em horas">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <input
              type="number"
              name="cargaHoraria"
              value={formData.cargaHoraria}
              onChange={handleChange}
              required
            />
          </div>
          <div className="inline-inputs">
            <div>
              <label htmlFor="dataInicio">Data de Início:</label>
              <input
                type="date"
                name="dataInicio"
                value={formData.dataInicio}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="dataFim">Data de Fim:</label>
              <input
                type="date"
                name="dataFim"
                value={formData.dataFim}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {isEditar && (
            <div>
              <div className="label-icon">
                <label htmlFor="anexo">Anexo:</label>
                <Tooltip text="Insira o anexo com o certificado para avaliação">
                  <GoQuestion size={iconSize} />
                </Tooltip>
              </div>
              <input
                type="file"
                name="anexo"
                className="file-input"
                value={formData.anexo}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div>
            <label htmlFor="observacao">Observação:</label>
            <textarea
              name="observacao"
              rows="4"
              value={formData.observacao}
              onChange={handleChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              width: "60%",
              marginLeft: "20%",
            }}
          >
            <CustomButton type="submit" >
              {isEditar ? "Cadastrar" : "Atualizar"}
            </CustomButton>
            <CustomButton variant="cancel" onClick={onBeforeCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Container>
      <ModalGenerico
        isOpen={isModalOpen}
        text="Se você sair, perderá seu progresso. Tem certeza que deseja sair?"
        onCancel={handleCancel}
        onProceed={handleCloseModal}
      />
    </>
  );
};

export default CertificadoForm;
