import React from "react";
import styled from "styled-components";
import { hex } from "Colors";
import { MdKeyboardArrowDown } from "react-icons/md";

const DivCampoStatus = styled.div`
  display: flex;
  justify-content: center;
`;

const CampoStatus = styled.span`
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 15px;
  margin-left: 5px;
`;

const StatusField = ({ status, upperCase = false, centered = false, onClickAction }) => {
  let label = "";
  let labelColor = "";

  switch (status) {
    case 3:
      label = upperCase ? "INDEFERIDO" : "Indeferido";
      labelColor = hex.vermelhoSecundario;
      break;
    case 2:
      label = upperCase ? "DEFERIDO" : "Deferido";
      labelColor = hex.verdePrimario;
      break;
    case 0:
      label = upperCase ? "PENDENTE" : "Pendente";
      labelColor = hex.amareloPrimario;
      break;
    case 1:
      label = upperCase ? "CONFERIDO" : "Conferido";
      labelColor = hex.laranjaPrimario;
      break;
    case 4:
      label = upperCase ? "RECURSO" : "Recurso";
      labelColor = hex.purple;
      break;
    default:
      label = "";
      labelColor = hex.cinzaPrimario;
      break;
  }

  return (
    centered ? (
      <DivCampoStatus>
        <CampoStatus
          onClick={onClickAction}
          style={{ backgroundColor: labelColor }}
        >
          {label}
          <MdKeyboardArrowDown />
        </CampoStatus>
      </DivCampoStatus>
    ) : (
    <CampoStatus
      onClick={onClickAction}
      style={{ backgroundColor: labelColor }}
    >
      {label}
    </CampoStatus>
    )
  );
};

export default StatusField;
