import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "./Button";

// Botão de cancelar que retorna para a página anterior
const CustomButtonCancelar = ({ label = "Sair" }) => {
  const navigate = useNavigate();

  const backPage = () => {
    navigate(-1);
  };

  return (
    <CustomButton variant="cancel" onClick={backPage}>
      {label}
    </CustomButton>
  );
};

export default CustomButtonCancelar;
