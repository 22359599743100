import React, { createContext, useState, useContext, useEffect } from "react";

const CertificadosContext = createContext();

export const CertificadosProvider = ({ children }) => {
  const [certificados, setCertificados] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCertificados = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("http://localhost:3001/certificates");
      const data = await response.json();
      setCertificados(data);
      setIsLoaded(true);
    } catch (error) {
      console.error("Erro ao buscar certificados", error);
      setError("Erro ao buscar certificados");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      fetchCertificados();
    }
  }, [isLoaded]);

  return (
    <CertificadosContext.Provider
      value={{ certificados, fetchCertificados, isLoaded, isLoading, error }}
    >
      {children}
    </CertificadosContext.Provider>
  );
};

export const useCertificadosContext = () => useContext(CertificadosContext);
