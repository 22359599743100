import Container from 'Components/container/Container'
import React from 'react'

const Suporte = () => {
  return (
    <Container titulo="Suporte">
      <p>Em caso de dúvida ou de eventuais problemas que acontecerem durante a utilização do sistema, é possível acionar o suporte por e-mail para <strong>suportegs@seduc.to.gov.br</strong> com as seguintes informações:</p>
      <br/>
      <p><strong>1</strong> - Informar no Assunto do e-mail de qual sistema se trata (neste caso o assunto seria: CERTIFICA - "problema x");</p>
      <br/>
      <p><strong>2</strong> - Descrição detalhada do erro, se possível explicando o passo a passo para chegar na tela de erro;</p>
      <br/>
      <p><strong>3</strong> - Captura de tela mostrando o erro;</p>
      <br/>
    </Container>
  )
}

export default Suporte
